import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <h1>Services</h1>
    <p>
      Services in progress. Services in progress. Services in progress. Services in progress.
      Services in progress. Services in progress. Services in progress. Services in progress.
      Services in progress. Services in progress.{' '}
    </p>
  </Layout>
);

export default ServicesPage;
